/*
  This function is directly linked to the routes published by the
  backend server. This is why it's defined as a “utils” function to
  avoid duplicating this logic in multiple stimulus controllers.

  @input url: URL
  @returns nodeId: String
  */
export function nodeIdFromUrl(url) {
  let parts = url.pathname.split(/group\/|operation\/|topic\//)
  parts.push(url.hash.slice(1))
  parts = parts.filter(element => element !== undefined && element !== "")
  let nodeId = parts.pop()

  // This is a specific entry to a “fake” node for the Authentication
  // or the Bindings paragraph
  if (nodeId.endsWith("/authentication")) {
    nodeId = "doc-authentication"
  }
  if (nodeId.endsWith("/bindings")) {
    nodeId = "protocol-information"
  }

  return nodeId
}

/* Takes an object and remove empty children */
export function cleanObject(object) {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === "object")
        cleanObject(v)
      if (v && 
          typeof v === "object" && 
          !Object.keys(v).length || 
          v === null || 
          v === undefined ||
          v.length === 0 
      ) {
        if (Array.isArray(object))
          object.splice(k, 1)
        else if (!(v instanceof Date))
          delete object[k]
      }
    })
  return object
}
