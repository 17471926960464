import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { serializeJSON } from "@syneto/serializejson"
import { CurlGenerator } from "curl-generator"
import { cleanObject } from "global/javascript/utils"

export default class extends Controller {
  static targets = ["requestForm", "responseContent", "responseStatus", "trackerForm", "urlForm", "preview", "apiKey", "icon", "loader"]

  static values = { enctype: String }

  // Response elements which needs to interact with the Syntax-Highlight controller
  static outlets = ["syntax-highlight"]

  connect() {
    this.defaultAction = this.requestFormTarget.action
    this.request = {
      url: this.requestFormTarget.action,
      method: this.#retrieveFormMethod(this.requestFormTarget),
      headers: {
        "Content-Type": this.enctypeValue,
        Accept: "application/json",
        ApiKey: this.apiKeyTarget.value
      }
    }
    this.updatePreview()
  }

  // For now we have a unique entrypoint to handle both successfully
  // (2xx) or errored (all other status) responses
  //
  // The event object has three elements which are defined by Rails UJS
  // https://github.com/rails/rails/blob/v7.1.3.4/actionview/app/assets/javascripts/rails-ujs.js#L91-L99
  onResponse(event) {
    // eslint-disable-next-line no-unused-vars
    const [response, statusText, xhr] = event.detail
    const status = xhr.status
    let content = response

    try {
      content = JSON.parse(response)
    } catch(error) {
      // empty
    }

    this.#setResponseStatusAndContent(status, JSON.stringify(content, null, 2))
    this.#toggleLoadingState(false)
  }

  onSubmit(event) {
    event.preventDefault()
    const form = this.requestFormTarget

    form.action = this.request.url

    if (!this.isLoading) {
      if (this.enctypeValue == "application/json") {
        this.#submitFormDataAsJson()
      } else {
        // TODO: Is it possible to submit headers in an HTML form
        // submission?
        //
        // Normal HTML form submission
        Rails.fire(form, "submit")
      }
      this.#toggleLoadingState(true)
      this.#trackRequest()
    }
  }

  updateApiKey() {
    this.request.headers["ApiKey"] = this.apiKeyTarget.value
    this.#generatePreview()
  }

  updatePreview() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const serializedUrlForm = serializeJSON(this.urlFormTarget)
      const serializedRequestForm = serializeJSON(this.requestFormTarget, {
        skipFalsyValuesForTypes: ["string"]
      })
      const pathParameters = serializedUrlForm["path_parameters"]
      const queryParameters = serializedUrlForm["query_parameters"]
      this.request.url = this.#computeUrl(pathParameters, queryParameters)
      this.request.headers = {...this.request.headers, ...serializedUrlForm["headers"]}
      this.request.body = serializedRequestForm
      this.#generatePreview()
    }, 500)
  }

  #generatePreview() {
    if (this.request.body._method) {
      delete this.request.body._method
    }
    cleanObject(this.request)
    this.previewTarget.innerHTML = CurlGenerator(this.request)
    this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.previewTarget)).highlight()
  }

  #toggleLoadingState(toggle) {
    this.loaderTarget.setAttribute("aria-hidden", !toggle)
    this.loaderTarget.setAttribute("aria-busy", toggle)
    this.iconTarget.setAttribute("aria-hidden", toggle)
    this.isLoading = toggle
  }

  // Replace path parameters in the given URL and add query parameters
  // as an URL encoded query string.
  #computeUrl(pathParameters, queryParameters) {
    let rawUrl = this.defaultAction

    if (pathParameters) {
      for (const [key, value] of Object.entries(pathParameters)) {
        rawUrl = rawUrl.replace(encodeURIComponent(`{${key}}`), value)
      }
    }

    let targetUrl = new URL(rawUrl)

    if (queryParameters) {
      targetUrl.search = new URLSearchParams(queryParameters)
    }

    return targetUrl.toString()
  }

  #submitFormDataAsJson() {
    const self = this

    const fetchOptions = {
      method: this.request.method,
      headers: this.request.headers,
      body: JSON.stringify(this.request.body)
    }

    fetch(this.request.url, fetchOptions)
      .then((response) => {
        response.text().then((data) => {
          // We build an event object passed to 'onResponse' to have
          // the same signature as the one defined by Rails UJS
          const event = {detail: [data, response.statusText, response]}

          self.onResponse(event)
        })
      })
      .catch((error) => {
        self.onResponse(
          {detail: [error, "", {}]}
        )
      })
  }

  #setResponseStatusAndContent(status, content) {
    this.responseStatusTarget.innerText = status
    this.responseStatusTarget.dataset.statusCode = status
    this.responseStatusTarget.removeAttribute("hidden")
    this.responseContentTarget.textContent = content
    this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.responseContentTarget)).highlight()
  }

  // Read the request method either from the hidden "_method" field or
  // from the form directly. This hidden field is the Rails magic for
  // PUT, PATCH or DELETE requests (because HTML forms only support
  // GET or POST requests)
  #retrieveFormMethod(form) {
    const serializedFormData = serializeJSON(form, {
      skipFalsyValuesForTypes: ["string"]
    })
    const method = serializedFormData["_method"] || form.method
    delete serializedFormData["_method"]
    return method
  }

  #trackRequest() {
    this.trackerFormTarget.requestSubmit()
  }
}
